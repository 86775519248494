import React from "react";
import Seo from "../components/Seo";
import IconInstagram from "../images/IconInstagram";

import logo from "../images/restoration-workshop.svg";
import logoDisc from "../images/rw-disc.svg";
import restoreVw from "../images/restore-vw.svg";

const IndexPage = () => {

  return (
    <>
      <Seo title="" description="" />
      <div className="w-full h-full min-h-screen" style={{ backgroundColor: "#E2DDDD" }}>
        <div className="container mx-auto h-full min-h-screen">
          <div className="h-full min-h-screen flex flex-col items-center justify-center mx-6 overflow-hidden py-6">
            <div className="flex flex-wrap w-full h-full">
              <div className="w-full md:w-1/3 flex justify-center">
                <img src={restoreVw} alt="Restoration Workshop" className="h-40" />
              </div>
              <div className="w-full md:w-1/3 flex justify-center">
                <img src={logo} alt="Restoration Workshop" className="h-40" />
              </div>
              <div className="w-full md:w-1/3 flex justify-center">
                <img src={logoDisc} alt="Restoration Workshop" className=" h-40" />
              </div>
            </div>
            
            <h1 className="text-center">Welcome to The Restoration Workshop</h1>
            <p className="text-center">Project posts and gallery coming soon of VW Golf MK3 GTI ABF restoration.</p>
            <div className="flex justify-center">
              <a href="https://www.instagram.com/restorationworkshop.co.uk/" target="_blank" rel="noreferrer" className="text-stone-800">
                <IconInstagram width={50} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default IndexPage;